<template>
  <v-data-table
    :headers="locationHeaders"
    :items="deviceLocations"
    :options.sync="deviceLocationOptions"
    :server-items-length="totalDeviceLocations"
    :items-per-page="5"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15],
    }"
  >
    <template v-slot:[`item.time`]="{ item }">
      <div>{{ $format.dateTime(item.time) }}</div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["currentMobileStatus"],
  data: () => ({
    locationHeaders: [
      { text: "Latitude", value: "latitude" },
      { text: "Longitude", value: "longitude" },
      { text: "Time", value: "time" },
    ],
    totalDeviceLocations: 0,
    deviceLocations: [],
    deviceLocationOptions: {},
  }),
  watch: {
    deviceLocationOptions: {
      handler(val) {
        if (val) {
          this.getDeviceLocations();
        }
      },
      deep: true,
    },
  },
  methods: {
    getDeviceLocations() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } =
        this.deviceLocationOptions;
      var data = {
        mobileStatusId: this.currentMobileStatus,
        page: page,
        limit: itemsPerPage,
      };
      this.$http("Device/GetDeviceLocations", { params: data })
        .then((response) => {
          this.totalDeviceLocations = response.data.total;
          this.deviceLocations = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.deviceLocations = [];
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>