export function throttle(callback, delay = 50) {
  let isDisabled = false

  return (...args) => {
    if (!isDisabled) {
      isDisabled = true

      callback(...args)

      setTimeout(() => {
        isDisabled = false
      }, delay)
    }
  }
}
