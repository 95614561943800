<template>
  <v-data-table
    :headers="statusHeaders"
    :items="deviceStatuses"
    :options.sync="deviceStatusesOptions"
    :server-items-length="totalDeviceStatuses"
    :items-per-page="5"
    :footer-props="{
      showFirstLastPage: true,
    }"
  >
    <template v-slot:[`item.lastConnected`]="{ item }">
      <div>{{ $format.dateTime(item.lastConnected) }}</div>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    props: ['currentMobileStatus'],
    data: () => ({
      deviceStatusesOptions: {},
      statusHeaders: [
        { text: 'OS', value: 'os' },
        { text: 'App Version', value: 'appVersion' },
        { text: 'User', value: 'username' },
        { text: 'IP Address', value: 'ipAddress' },
        { text: 'Zone', value: 'zone' },
        { text: 'Signal Strength', value: 'signalStrength' },
        { text: 'BSSID', value: 'bssid' },
        { text: 'Link Speed', value: 'linkSpeed' },
        { text: 'ESSID', value: 'essid' },
        { text: 'Last Connected', value: 'lastConnected' },
      ],
      totalDeviceStatuses: 0,
      deviceStatuses: [],
    }),
    watch: {
      deviceStatusesOptions: {
        handler (val) {
          if (val) {
            this.getDeviceStatuses()
          }
        },
        deep: true,
      },
    },
    methods: {
      getDeviceStatuses () {
        if (!this.totalDeviceStatuses || this.totalDeviceStatuses == 0) {
          this.loading = true
          const { sortBy, sortDesc, page, itemsPerPage } =
            this.deviceStatusesOptions
          var data = {
            mobileStatusId: this.currentMobileStatus,
            page: page,
            limit: itemsPerPage,
          }
          this.$http('Device/GetDeviceStatuses', { params: data })
            .then((response) => {
              this.totalDeviceStatuses = response.data.total
              this.deviceStatuses = response.data.data
              this.loading = false
            })
            .catch(() => {
              this.mobileStatuses = []
              this.loading = false
            })
        }
      },
    },
  }
</script>

<style>
</style>
