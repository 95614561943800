<template>
  <v-data-table
    :headers="appsHeaders"
    :items="deviceApps"
    :search.sync="appSearch"
    :options.sync="deviceAppOptions"
    :server-items-length="totalDeviceApps"
    :items-per-page="5"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15],
    }"
  />
</template>

<script>
export default {
  props: ["currentMobileStatus"],
  data: () => ({
    appsHeaders: [{ text: "applicationName", value: "applicationName" }],
    totalDeviceApps: 0,
    deviceApps: [],
    deviceAppOptions: {},
    appSearch: "",
  }),
  watch: {
    deviceAppOptions: {
      handler(val) {
        if (val) {
          this.getDeviceApps();
        }
      },
      deep: true,
    },
  },
  methods: {
    getDeviceApps() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.deviceAppOptions;
      var data = {
        mobileStatusId: this.currentMobileStatus,
        page: page,
        limit: itemsPerPage,
      };
      this.$http("Device/GetDeviceApps", { params: data })
        .then((response) => {
          this.totalDeviceApps = response.data.total;
          this.deviceApps = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.deviceApps = [];
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>