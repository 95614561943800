<template>
  <div>
    <v-dialog v-model="show" width="600px">
      <v-card color="#f4f6f9">
        <v-card-title> Device Messages </v-card-title>

        <v-card-text>
          <v-row class="ma-2"
            ><v-text-field
              label="Message"
              v-model="message"
              outlined
              class="mr-2"
            />
            <v-btn color="primary" x-large @click="sendMessage()">
              Send
            </v-btn></v-row
          >
          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
          >
            <template v-slot:[`item.receivedAt`]="{ item }">
              <div v-if="item.isSent">{{ item.receivedAt }}</div>
            </template></v-data-table
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeviceMessageDialog",
  props: ["value", "deviceId"],
  data: () => ({
    message: "",
    items: [],
    totalItems: 0,
    options: {},
    loading: false,
    headers: [
      {
        text: "Message",
        sortable: false,
        value: "description",
      },
      {
        text: "SentAt",
        sortable: false,
        value: "sentAt",
      },
      {
        text: "ReceivedAt",
        sortable: false,
        value: "receivedAt",
      },
    ],
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    show(val) {
      val || this.close();
    },
    deviceId() {
      this.getMessages();
    },
  },

  methods: {
    getMessages() {
      this.loading = true;
      this.$http
        .get("message/getMessages", {
          params: { deviceId: this.deviceId, page: 1, limit: 10 },
        })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.totalItems = response.data.total;
        });
    },
    sendMessage() {
      this.$http
        .get("message/sentMessageToDevice", {
          params: { deviceId: this.deviceId, message: this.message },
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
          this.getMessages();
        });
    },
    close() {
      this.show = false;
    },
  },
};
</script>