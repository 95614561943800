<template>
  <div class="mt-4">
    <div class="mx-1 mb-4" style="display: flex; padding: 15px 20px; border-radius: 5px; background: #f5f5f5;">
      <div style="flex: 1">
        <config-form v-model="form" />
      </div>

      <app-btn class="ml-5 mt-2" @click="addConfig">
        Add
      </app-btn>
    </div>

    <editable-config-table item-disable="isCompanyConfiguration" v-model="items" @update="update" @remove="remove" />
  </div>
</template>

<script>
import EditableConfigTable from './config/EditableConfigTable.vue'
import { get } from 'vuex-pathify'
import ConfigForm from './config/ConfigForm.vue';

export default {
  components: { EditableConfigTable, ConfigForm },
  props: {
    deviceId: Number,
  },
  data: () => ({
    form: null,
    items: [],
  }),

  computed: {
    ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    deviceId() {
      this.items = []
      this.load()
    },
  },

  created() {
    this.load()
  },

  methods: {
    load() {
      this.getDeviceConfigurations(this.deviceId).then(data => {
        this.items = data.filter(x => !x.isCompanyConfiguration)
      })
    },
    async getDeviceConfigurations(id) {
      if (!id || id === 0) {
        return
      }

      try {
        const res = await this.$http(`/DeviceConfiguration?deviceid=${id}`)
        if (res.data?.success) {
          return res.data.data || []
        }
      } catch (error) {

      }

      return []
    },
    update(item) {
      if (!item.configurationId && item.configurationId === 0) {
        return
      }

      this.$http.put(`/DeviceConfiguration/${item.configurationId}`, item).then(response => {
        if (response.data.success) {
          this.$toast.success('Config updated successfully.', 'Success', {
            position: 'topRight',
          })
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },
    remove(item) {
      this.$http.delete(`/DeviceConfiguration/DeleteDeviceConfiguration?id=${item.id}`).then(response => {
        if (response.data.success) {
          this.$toast.success('Config removed successfully.', 'Success', {
            position: 'topRight',
          })

          const index = this.items.indexOf(item)
          this.items.splice(index, 1)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })
    },
    addConfig() {
      const data = this.form.getData()
      if (data.name == null || data.value == null) {
        return
      }

      data.deviceId = this.deviceId

      this.$http.post(`DeviceConfiguration/AddDeviceConfiguration`, data).then(response => {
        if (response.data.success) {
          this.$toast.success(response.data.message, 'Success', {
            position: 'topRight',
          })

          this.items.unshift(response.data.data)
        } else {
          this.$toast.error(response.data.message, 'Error', {
            position: 'topRight',
          })
        }
      })

      this.form.assignDefaults()
    }
  },
}
</script>
