<template>
  <v-data-table
    :headers="licenseHeaders"
    :items="deviceLicenses"
    :options.sync="deviceLicenseOptions"
    :server-items-length="totalDeviceLicenses"
    :items-per-page="5"
    :footer-props="{
      showFirstLastPage: true,
      'items-per-page-options': [5, 10, 15],
    }"
  />
</template>

<script>
export default {
  props: ["currentMobileStatus"],
  data: () => ({
    licenseHeaders: [{ text: "License Number", value: "licenseNumber" }],
    totalDeviceLicenses: 0,
    deviceLicenses: [],
    deviceLicenseOptions: {},
  }),
  watch: {
    deviceLicenseOptions: {
      handler(val) {
        if (val) {
          this.getDeviceLicenses();
        }
      },
      deep: true,
    },
  },
  methods: {
    getDeviceLicenses() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } =
        this.deviceLicenseOptions;
      var data = {
        mobileStatusId: this.currentMobileStatus,
        page: page,
        limit: itemsPerPage,
      };
      this.$http("Device/GetDeviceLicenses", { params: data })
        .then((response) => {
          this.totalDeviceLicenses = response.data.total;
          this.deviceLicenses = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.deviceLicenses = [];
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>