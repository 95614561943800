<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <img
      v-if="deviceModel == 'TC21'"
      src="@/assets/TC21&26.jpg"
      width="100"
    >
    <img
      v-else-if="deviceModel.includes('EDA52')"
      src="@/assets/EDA52.png"
      width="100"
    >
    <img
      v-else-if="deviceModel.includes('TC57X')"
      src="@/assets/TC57X.jpg"
      width="100"
    >
    <img
      v-else-if="deviceModel.includes('MC33')"
      src="@/assets/MC33.png"
      width="100"
    >
    <img
      v-else-if="
        deviceModel.includes('WT63')
      "
      src="@/assets/WT63B0.jpg"
      width="100"
    >
    <img
      v-else-if="
        deviceModel.includes('CK65')
      "
      src="@/assets/ck65.png"
      width="100"
    >
    <div
      v-else
      class="text-center"
    >
      <img
        src="@/assets/defaultDevice.png"
        width="100"
      >
    </div>
    <span>{{ deviceModel }}</span>
  </div>
</template>
<script>
  export default {
    name: 'DeviceIcon',
    props: {
      icon: String,
      deviceModel: {
        type: String,
        default: '',
      },
    },
  }
</script>
