<template>
  <div>
    <v-dialog v-model="appDialog" width="500px">
      <v-card>
        <v-card-title> Apps List </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-file-input v-model="apkFile" label="File" />

              <v-btn
                color="primary"
                dark
                class="mt-2 ml-2"
                @click="uploadApk()"
              >
                <v-icon class="mr-2">mdi-plus </v-icon>
                Add
              </v-btn>
            </v-row>
            <v-data-table :headers="headers" :items="items" class="mt-2"
              ><template v-slot:[`item.action`]="{ item }">
                <v-icon color="error" @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AppDialog",
  props: ["dialog"],
  data: () => ({
    appDialog: false,
    dialogDelete: false,
    headers: [
      { text: "App lik", value: "path" },
      { text: "Action", align: "end", value: "action" },
    ],
    items: [],
    option: "",
    apkFile: null,
  }),
  watch: {
    dialog(val) {
      this.appDialog = val;
    },
    appDialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getAppList();
  },
  methods: {
    getAppList() {
      this.$http.get("Device/GetAppsList").then((response) => {
        this.items = response.data;
      });
    },

    deleteItem(item) {
      this.$http
        .delete("Device/DeleteApp", { params: { link: item.path } })
        .then((response) => {
          if (response.data.success) {
            let index = this.items.findIndex((x) => x.path === item.path);
            this.items.splice(index, 1);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });
    },

    close() {
      this.appDialog = false;
      this.apkFile = null;
      this.$emit("close");
    },
    uploadApk() {
      var form = new FormData();
      form.append("formFile", this.apkFile);
      this.$http.post("Device/UploadApp", form).then((response) => {
        if (response.data.success) {
          this.items.push({ path: response.data.data });
          this.$toast.success(response.data.message, "Success", {
            position: "topRight",
          });
        } else {
          this.$toast.error(response.data.message, "Error", {
            position: "topRight",
          });
        }
        this.apkFile = null;
      });
    },
  },
};
</script>

<style>
</style>